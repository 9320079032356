import { Container } from "./styles";
// import VinayakSingh from "../../assets/VinayakSingh.png";
import nextJsIcon from "../../assets/nextjs-icon.svg";
import graphQL from "../../assets/graphql-icon.svg";
import mongoDB from "../../assets/mongodb-icon.svg";
import nodeIcon from "../../assets/node-icon.svg";
import mySql from "../../assets/mysql-icon.svg";
import angular from "../../assets/angular-icon.svg";
import django from "../../assets/djang-icon.svg";
import ScrollAnimation from "react-animate-on-scroll";

export function About() {
  return (
    <Container id="about">
      <div className="about-text">
        <ScrollAnimation animateIn="fadeInLeft">
          <h2>About me</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.1 * 1000}>
          <p>
            I specialize in developing secure and efficient crypto trading platforms, leveraging the power of the MERN/MEAN stack. 
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.2 * 1000} style={{ marginTop: "2rem", marginBottom: "2rem" }}>
          <p>
            My expertise centers around building centralized exchanges, seamlessly integrating APIs, and ensuring timely project delivery.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.3 * 1000}>
          <p>
            I'm commited to staying updated with industry trends and best practices, thriving within dynamic, collaborative environmments.
          </p>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft" delay={0.4 * 1000}>
          <h3>Here are my main skills:</h3>
        </ScrollAnimation>
        <div className="hard-skills">
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.10 * 1000}>
              <img src={nodeIcon} alt="nodeIcon" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.17 * 1000}>
              <img src={nextJsIcon} alt="nextJs" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
              <img src={mongoDB} alt="mongoDB" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.13 * 1000}>
              <img src={mySql} alt="mySql" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.17 * 1000}>
              <img src={graphQL} alt="GraphQL" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
              <img src={angular} alt="angular" />
            </ScrollAnimation>
          </div>
          <div className="hability">
            <ScrollAnimation animateIn="fadeInUp" delay={0.14 * 1000}>
              <img src={django} alt="django" />
            </ScrollAnimation>
          </div>
        </div>
      </div>
      <div className="about-image">
        <ScrollAnimation animateIn="fadeInRight" delay={0.20 * 1000}>
          {/* <img src={VinayakSingh} alt="Vinayak Singh" /> */}
        </ScrollAnimation>
      </div>
    </Container>
  )
}
